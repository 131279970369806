import { Component, Input, Output, EventEmitter, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmableActionService, DesignSystemModule } from '@peca/design-system';
import { CommonsModule } from '@peca/commons';
import { DialectService } from '../../services/dialect.service';
import { QueryResponseDto } from '../../dtos/responses/QueryResponseDto';

@Component({
  standalone: true,
  imports: [RouterModule, CommonModule, MatCardModule, MatButtonModule, MatIconModule, DesignSystemModule, CommonsModule],
  selector: 'peca-query',
  templateUrl: './query.component.html',
  styleUrls: ['./query.component.scss'],
})
export class QueryComponent {
  confirmable: ConfirmableActionService;

  constructor(private dialectService: DialectService) {
    this.confirmable = inject(ConfirmableActionService);
  }

  @Input() query?: QueryResponseDto;
  @Output() reload = new EventEmitter<string>();

  public clickAgain = false;

  delete() {
    this.confirmable.confirm('Você está apagando uma query', null, true).subscribe(({ confirmed }) => {
      if (!confirmed || !this.query) return;

      this.dialectService.deleteQuery(this.query.id).subscribe(() => {
        this.reload.emit(this.query?.id ?? '');
      });
    });
  }
}
